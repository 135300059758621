import React from "react"
import styled from '@emotion/styled'
import Container from '../components/container'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { mediaQuery } from "../components/shared-styles"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import logoShape from '../images/logo-shape.svg';

const Image = styled(Img)({
  width: '100%',
  height: '100%',
});

const StyledContainer = styled(Container)({
  height: '100%',
});

const Text = styled('div')({
  width: '300px',
  height: '300px',
  backgroundImage: `url(${logoShape})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  position: 'absolute',
  top: 'calc(50% - 150px)',
  left: 'calc(50% - 150px)',
  padding: '10px 0',
  [mediaQuery.medium]: ({
    padding: '10px 30px',
  })
});

const PageTitle = styled('h1')({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'black',
  opacity: 0.85,
  fontWeight: 100,
  marginTop: '-20px',
  fontSize: '1.5rem',
  [mediaQuery.large]: ({
    fontSize: '2rem',
  })
});

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    file(relativePath: { eq: "homepage.jpg" }) { ...fluidImage }
  }
`

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Úvod" />
    <Image fluid={data.file.childImageSharp.fluid} />
    <StyledContainer>
      <Text>
        <PageTitle>SvobodaDesign</PageTitle>
      </Text>
    </StyledContainer>
  </Layout>
)

export default IndexPage
